.toolbar-sorter {
    background-color: @white;

    .sorter-actions,
    .filter-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        .button {
            .button-red;
            margin-bottom: @padding-mobile;
        }
    }
}

.button-toggle-filters {
    .button-red;
    display: block;
    text-align: center;
    width: 100%;

    .filters-open & {
        .button-dark();

        .icon {
            fill: @white;
            stroke: @white;
        }
    }

    .icon {
        display: inline-block;
        height: 16px;
        stroke: @black;
        fill: @black;
        margin-bottom: -4px;
    }

    .label-hide {
        display: none;
    }

    .filters-open & {
        .label-hide {
            display: inline-block;
        }

        .label-show {
            display: none;
        }
    }
}

.toolbar {
    color: @text-1;
}

.filters {
    .filter-content {
        padding: @padding-mobile;
    }

    label {
        width: 100%;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 1.1rem;
        position: initial;
    }
}

.toolbar {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;

    &.toolbar-products {
        &:before {
            display: none;
        }
    }

    .toolbar-amount {
        width: auto;
        flex: 1 1 100%;

        @media screen and (min-width: 600px) {
            flex: 0 1 auto;
        }
    }

    .toolbar-amount-label {
        @media screen and (min-width: 600px) {
            display: block;
        }
    }

    .toolbar-amount-value {
        font-weight: 700;
    }

    .sorter {
        margin-left: auto;
        display: flex;
        align-items: center;

        &-label {
            white-space: nowrap;
            cursor: default;
            display: none;

            @media screen and (min-width: 600px) {
                display: block;
            }
        }

        &-options {
            min-width: 125px;
            background-color: transparent;
        }
    }
}

.limiter {
    width: auto;

    label {
        .screen-readers;
    }

    .nice-select {
        background-color: transparent;
    }

    .limiter-options {
        width: auto;
    }
}

.filter-current {
    display: none;
}

.filter-options-item {
    margin-top: @padding-mobile;
    margin-bottom: @padding-mobile;

    .filter-options-title {
        display: flex;
        line-height: 1.1;
        height: 100%;
        align-items: center;
        padding-top: @padding-mini;
        margin-bottom: 0;
        font-weight: 400;
    }

    .filter-options-content {
        display: block !important;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        transform-origin: 50% 0;
        transition: all 0.25s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.25s ease-out;
        transition-delay: 100ms;
        transform: scale(0.75) translateY(-21px);
        z-index: 9;
        background-color: @white;
        min-width: 100%;
        max-width: 320px;
    }

    .items {
        padding: @padding-mini * 2 @padding-mobile;
    }

    .item {
        a {
            .checkbox-styled;
            text-decoration: none;
            display: block;
            line-height: 1.2;
            max-width: 100%;
            padding-bottom: .5rem;
            padding-top: .5rem;
            white-space: normal;

            label,
            .count {
                color: @text-1;
            }

            label {
                white-space: normal;
                line-height: 1.5;
                display: flex;
                align-items: center;
                left: 0;

                &:before {
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    &.open {
        z-index: 100;

        .filter-options-content {
            opacity: 1;
            pointer-events: auto;
            overflow: visible;
            transform: scale(1) translateY(0);
        }
    }

    .smile-es-range-slider {
        padding: @padding-mobile;

        .ui-slider-handle {
            background-color: @form-border;
        }

        .ui-slider-horizontal {
            background-color: @gray;
        }

        .action {
            .button;
            .button-small;
        }

        .actions-toolbar {
            margin-bottom: 0;
        }

        [data-role=message-box] {
            white-space: normal;
            line-height: 1.2;
        }
    }

    .no-results-message {
        margin: 0 @padding-mobile;
    }

    .actions {
        padding: 1rem;
        text-align: right;

        .action {
            .button;
            .button-small;
        }
    }
}

.filter-current,
.filter-actions {
    .action {
        .button-dark;
    }
}

.filter-subtitle {
    width: 100%;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.1rem;
    color: @text-2;
}

.minW(@tablet-md + 1, {

        .button-toggle-filters {
            display: none;

            .filters-open & {
                display: none;
            }
        }

        .filter-options-item {
            margin-top: 0;
            margin-bottom: 0;
        }

        .toolbar-sorter {
            float: none;

            .sorter-actions,
            .filter-options {
                justify-content: space-between;
                align-items: center;

                .button {
                    .button-dark();
                    margin-bottom: 0;
                }
            }

            .filter-options {
                display: grid;
                grid-auto-columns: minmax(0, 1fr);
                grid-auto-flow: column;
                gap: @padding-mobile;

                .filter-options-title {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inherit;
                    line-height: 2;
                }

                .filter-options-item {
                    max-width: 100%;
                }
            }

            .nice-select, .select2 {
                flex: 1;
                margin: 0 @padding-mobile 0 0;
                max-width: 50%;
            }
        }

        .filters {
            box-shadow: @box-shadow-content;
        }

        .toolbar {
            flex-direction: row;

            .toolbar-amount {
                width: calc(~"33.33% - @{padding-mobile} * 2");
                margin: 0 @padding-mobile 0 0;
            }
        }

        .limiter {
            width: calc(~"33.33% - @{padding-mobile} * 2");
            margin: 0 @padding-mobile;
        }

    }

);

.maxW(@tablet-md, {
        .toolbar-sorter {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            background-color: transparent;
            max-width: 400px;
            margin: auto;

            .filters {
                max-height: 0;
                overflow: hidden;
            }

            .filter-subtitle,
            .filter-content:before {
                display: none;
            }

            &.filters-open {
                box-shadow: 0 7px 20px -7px @category-item-shadow;
                background-color: @white;

                .filters {
                    max-height: 600px;
                    overflow: visible;
                }
            }
        }

        .filters {
            label {
                .screen-readers;
            }

            .nice-select, .select2 {
                margin: @padding-mobile 0;
            }
        }

        .limiter {
            .nice-select {
                margin: @padding-mobile 0;
            }
        }

        .filter-options-item {
            .filter-options-content {
                max-width: 100%;
            }
        }
    }

);

.minMax(@mobile-max, @tablet-md, {
        .toolbar {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .toolbar-amount {
                flex: 1 0 auto;
                max-width: calc(~"50% - @{padding-mobile} * 2");
            }

            .limiter {
                max-width: calc(~"50% - @{padding-mobile}");
                flex: 1 0 auto;
            }
        }
    }

);

.minW(@desktop-md, {
        .toolbar {
            .toolbar-amount {
                width: calc(~"25% - @{padding-mobile} * 2");
            }
        }

        .limiter {
            width: calc(~"25% - @{padding-mobile} * 2");
        }
    }

);

.maxW(@mobile-max, {
        .toolbar-sorter + .toolbar-products {
            flex-direction: row;
            margin-bottom: 0;
        }
    }

);