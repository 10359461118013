@import './product-list/filters';
@import './product-list/item';
@import './product-list/delivery-installation';

.category-short-description {
    margin-bottom: 30px;
}

.category-description {
    padding-bottom: @padding-tablet;
    max-width: 800px;

    a {
        text-decoration: underline;
    }
}

.page-products {
    .page-title-wrapper {
        text-transform: uppercase;
    }

    .product-item-details {
        .availability {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 10px 0;
        }

        .price-box {
            &.price-final_price {
                padding-bottom: 0;
            }
        }
    }

    .page-title-wrapper {
        display: flex;

        .minW(@desktop-min, {
                justify-content: space-between;

                .page-title {
                    max-width: 60%;
                }
            }

        );

        .minW(@desktop-md, {
                .page-title {
                    max-width: 50%;
                }
            }

        );

        .maxW(@desktop-sm, {
                flex-direction: column-reverse;
            }

        );
    }

    .labels-container {
        .minMax(@tablet-max, @desktop-sm, {
                display: flex;
                justify-content: flex-end;
            }

        );
    }

}

.product-items {
    .list-reset;
    padding-top: @padding-mobile;

    .item,
    .product-item {
        margin: @padding-tablet auto;
    }

    .product-availability {
        color: @green;

        &.product-out-of-stock {
            color: @red;
        }
    }
}

.page-products .toolbar .limiter {
    display: block;
}

.page-title {
    .base+.category-filter {
        margin-top: @padding-mobile;
    }

    .category-filter {
        font-size: 16px;
        font-weight: 400;
        text-transform: none;
        color: @text-2;
        display: block;
        line-height: 1.5;

        &:before {
            content: '';
            display: inline-flex;
            .icon('arrow-small-active');
            background-position: center;
            transform: rotate(180deg);
            width: 3rem;
            height: 1rem;
        }
    }
}


.maxW(@tablet-max, {
        .products-grid .product-items .product-item {
            padding: 0 0 @addtocart-h;
        }
    }

);

.toolbar-products {
    text-align: left;
    padding: 0;
}

.products-grid {
    padding-top: @padding-lg;
    padding-bottom: @padding-lg;

    +.toolbar-sorter,
    +.toolbar-sorter+.toolbar-products .limiter {
        display: none;
    }

    .label {
        .attribute-label();
    }

    .value {
        .attribute-value();
    }
}

.minW(@mobile-max, {
        .columns .products-grid .product-items {
            display: flex;
            flex-wrap: wrap;
            margin-left: -@padding-mobile;
            margin-right: -@padding-mobile;
            min-width: 100%;

            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"50% - @{padding-mobile} * 2");
            }
        }
    }

);

.minW(@tablet-md, {
        .columns .products-grid .product-items {
            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"33.33% - @{padding-mobile} * 2");
            }
        }
    }

);

.minW(@desktop-min, {
        .columns .products-grid .product-items {
            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"50% - @{padding-mobile} * 2");
            }
        }
    }

);

.minW(1150px, {
        .columns .products-grid .product-items {
            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"33.33% - @{padding-mobile} * 2");
            }
        }
    }

);

.minW(1500px, {
        .columns .products-grid .product-items {
            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"25% - @{padding-mobile} * 2");
            }
        }
    }

);

.minW(1750px, {
        .columns .products-grid .product-items {
            .item, .product-item {
                margin: @padding-mobile;
                width: calc(~"20% - @{padding-mobile} * 2");
            }
        }
    }

);

.maxW(@mobile-max, {

        .products-grid,
        .product-items {
            margin: 0;
            padding: 0;

            > .item:first-of-type {
                margin-top: 0;
            }
        }
    }

);

.product-item-sku {
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    display: block;
}