.product-options-wrapper {
    .swatch-opt {
        margin: 0;

        .swatch-attribute-options {
            display: flex;
            flex-wrap: wrap;
        }

        .swatch-option {
            border-color: @border-light;

            &.text {
                background: @white;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #000;
                border-color: #000;
                width: calc(20% - 4px);
                margin: 2px;
                height: 30px;
                font-weight: 500;

                &.selected {
                    border-color: @red;
                    color: @red;
                    font-weight: bold;
                    outline-color: @red;
                }
            }
        }
    }

    .fieldset {
        >.field.configurable {
            &:last-of-type {
                margin-bottom: 2rem;
            }
        }
    }

    .swatch-option-link {
        pointer-events: none;
        text-decoration: none;
    }
}

.swatch-attribute-options {
    margin: 0;
}

.swatch-attribute.kolor {
    [data-option-label=Szary] {
        background-color: grey;
    }

    [data-option-label=Srebrny] {
        background-color: silver;
    }

    [data-option-label=Czarny] {
        background-color: black;
    }
}

.swatch-option-dropdown {

    padding-top: @padding-mini;
    padding-bottom: @padding-mini;

    &.selected {
        border-color: @red;
        color: @red;
        font-weight: bold;
        outline-color: @red;
    }
}

.sidebar-scrollable {
    display: block;

    .block-order {
        .block-content {
            .loading-spinner {
                display: none;
            }

            &.overlays {
                position: relative;

                .loading-spinner {
                    display: block;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    bottom: 0;
                    width: 100%;
                    opacity: .85;
                    background-color: #fff;
                    left: 0;

                    &:after {
                        content: '';
                        position: absolute;
                        top: calc(50% - 15px);
                        left: calc(50% - 15px);
                        width: 30px;
                        height: 30px;
                        border: 2px solid rgba(125, 125, 125, .6);
                        border-top-color: #fff;
                        border-radius: 50%;
                        -webkit-animation: loading 1s ease infinite;
                        animation: loading 1s ease infinite;
                        z-index: 10;
                    }

                    .spinner {
                        width: 30px;
                        height: 30px;
                    }
                }
            }
        }
    }
}